import mouse from "../../imgs/almouse.jpg"
import "./HomePage.css";

const HomePage = () => {
  return (
    <div id="homepage-container">
      <img src={mouse} id='mouse-img' alt="mouse painting"/>
    </div>
  );
};

export default HomePage;
